import * as React from "react";
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import { EventApi, EventAttributes, TicketPaymentDataAttributesResultEnum } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import { InhaltElem } from "../components/Text/SmallerInhalt";

interface ReservationCancelledTemplateProps {
  pageContext: {
    attributes: EventAttributes;
    id: string;
  };
}

const api = new EventApi(defaultAPIConfig());

const ReservationCancelledTemplate: React.FC<
  ReservationCancelledTemplateProps
> = (props) => {
  const [tickets, setTickets] = useState([]);
  // All Data which can be prepared at build time
  const {
    pageContext: {
      attributes: {
        description,
        date,
        // @ts-ignore
        ticketing: { ticketCategories },
      },
      id,
    },
  } = props;


  // All realtime data (ticket availability, ..)
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referenceId = params.get("referenceId");
    const eventId = params.get("eventId");

    const tellBackend = async () => {

      const payload = {ticketPayment: {
          data: {
            type: "ticketpayment",
            attributes: {
              result: TicketPaymentDataAttributesResultEnum.Cancelled,
              eventId,
              referenceId,
            },
          }
        }
      };
      try{
      const response = await api.ticketpaymentPost(
        payload, {
          headers: {
            accept: "application/vnd.api+json",
            "content-type": "application/vnd.api+json",
          },
        }
      );
      // console.log('success',response);
      }catch(error){
        // console.log('There was an error: ', error)
      }
    };

    tellBackend();
  }, []);



  return (
    <DefaultLayout>
      <Helmet>
        <title>Reservation cancelled! - STEP</title>
      </Helmet>
      <Page>
         <Container>
          <ContentWrapper>
            <br />
            <br />
            <br />
            <InhaltElem>
              <h1>Reservation cancelled</h1>
              <br />
              <p>Please try again.</p>
            </InhaltElem>
          </ContentWrapper>
        </Container>
      </Page>
    </DefaultLayout>
  );
};

export default ReservationCancelledTemplate;
